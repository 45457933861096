<template>
  <div>
    <div v-show="visible" class="info">
      <top-bar :title="!submitData.id?'新增肇事':edit?'修改肇事':'查看肇事'" :left="true"></top-bar>
      <van-popup v-model="communityShow" position="bottom">
        <van-picker
            show-toolbar
            value-key="label"
            :columns="selectData.communityList"
            @confirm="communityConfirm"
            @cancel="communityShow = false"
        />
      </van-popup>
      <!-- 肇事时间 -->
      <van-popup v-model="causeDateShow" position="bottom" >
        <van-datetime-picker v-model="selectMsg.causeDate" type="date" title="请选择"
                             @cancel="causeDateShow = false" @confirm="timeConfirm"/>
      </van-popup>
      <!-- 处理时间 -->
      <van-popup v-model="resultDateShow" position="bottom" >
        <van-datetime-picker v-model="selectMsg.resultDate" type="date" title="请选择"
                             @cancel="resultDateShow = false" @confirm="resultDateConfirm"/>
      </van-popup>
      <van-cell-group>
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">肇事信息</span>
          </template>
        </van-cell>
        <van-cell title="肇事人">
          <van-field clearable clear-trigger="always" v-model="submitData.userName" placeholder="请输入" readonly/>
        </van-cell>
        <van-cell title="肇事所在社区" :value="selectMsg.communityStr" :clickable="edit" @click="communityShow = edit"
                  :value-class="{'value-common':selectMsg.communityStr=='请选择'}" />
        <van-cell title="肇事日期" :value="submitData.causeDate" @click="causeDateShow = edit"
                  :value-class="{'value-common':submitData.causeDate=='请选择'}" :is-link="edit" />
        <van-field  clearable clear-trigger="always" v-model="submitData.content" rows="4" class="left info-title" :border="false" autosize type="textarea" placeholder="肇事事由" :readonly="!edit"/>
      </van-cell-group>

      <van-cell-group class="info-second">
        <van-collapse v-model="activeNames">
          <van-collapse-item name="1">
            <template #title>
              <div class="blue-box"></div>
              <span class="custom-title">处理</span>
            </template>
            <template #right-icon>
              <van-switch ref="switch" v-model="checked"  @change="checkedChange" size="24px"/>
            </template>
            <template v-if="checked">
              <van-cell title="处理日期" :value="submitData.resultDate" @click="resultDateShow = edit"
                      :value-class="{'value-common':submitData.resultDate=='请选择'}" :is-link="edit" />
              <van-field  clearable clear-trigger="always" v-model="submitData.processResult" rows="4" class="left info-title" :border="false" autosize type="textarea" placeholder="处理结果" :readonly="!edit"/>
            </template>
              
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>

      <van-row class="btns">
        <van-col :span="24" v-if="edit">
          <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
        </van-col>
        <van-row gutter="10" v-else>
          <van-col :span="12">
            <van-button plain type="danger" size="large" round @click="deleteHandle" style="background-color:transparent">删除</van-button>
          </van-col>
          <van-col :span="12">
            <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
          </van-col>
        </van-row>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDate} from "@/utils/utils"
import {getVirtualDict} from '@/utils/common'
// import placeList from "../visit/placeList"

export default {
  data() {
    return {
      i: '',
      visible: true,
      edit: false,
      file: {},
      detailData: {},
      checked: true,
      activeNames: ['1'],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      communityShow: false,
      causeDateShow: false,
      resultDateShow: false,
      selectMsg: {
        armyStr: '请选择',
        communityStr: '请选择',
        typeStr: '请选择',
        causeDate: new Date(),
        resultDate: new Date()
      },
      submitData: {
        id: '',
        army: '',
        community: '',
        typeId: '',
        userName: '',
        userId: '',
        causeDate: '',
        content: '',
        resultDate: '',
        processResult: '',
        type: ''
      },
      selectData: {
        armyList: [{label: "是", value: "1"}, {label: "否", value: "0"}],
        communityList: [],
        typeList: [{value:"1",label:"个人访"},{value:"2",label:"集体访"}],
      }
    }
  },
  components: {
    topBar,
    // placeList
  },
  methods: {
    getCommunity() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/res/allCommunity`),
        method: 'get',
        params: this.$http.adornParams({
          userId: this.submitData.userId,
          orgId: this.$orgId,
          labelShortName: '精',
          orgUserId: this.$globalData.userInfo.userId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.selectData.communityList = data.list
        }
      })
    },
    checkedChange () {
      if (!this.checked) {
        this.activeNames = ['1']
      } else {
        this.activeNames = []
      }
    },
    // 初始化
    init() {
      // getVirtualDict('petitioncommunity', (dict) => {
      //   this.selectData.communityList = dict
      // })
      this.getCommunity()
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/trouble/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.submitData.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.userName = data.appTrouble.userName
          this.submitData.userId = data.appTrouble.userId
          this.submitData.processResult = data.appTrouble.processResult
          this.submitData.community = data.appTrouble.community
          this.submitData.resultDate = data.appTrouble.resultDate
          this.submitData.causeDate = data.appTrouble.causeDate
          this.submitData.content = data.appTrouble.content
          this.selectMsg.communityStr = data.appTrouble.communityStr
          this.checked = data.appTrouble.isSolve == 1
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/trouble/delete'),
          method: 'post',
          params: this.$http.adornParams({
            ids: parseInt(this.submitData.id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 提交
    submit() {

      if (this.submitData.causeDate === '') {
        return this.$toast.fail('失败:肇事时间不能为空');
      }
      if (this.submitData.content === '') {
        return this.$toast.fail('失败:肇事描述不能为空');
      }
      if (this.submitData.resultDate === '' && this.checked) {
        return this.$toast.fail('失败:处理时间不能为空');
      }
      if (this.submitData.processResult === '' && this.checked) {
        return this.$toast.fail('失败:处理描述不能为空');
      }
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/trouble/${this.submitData.id ? 'update' : 'save'}`),
        method: 'post',
        data: {
          causeDate: this.submitData.causeDate,
          community: this.submitData.community,
          communityStr: this.submitData.communityStr,
          content: this.submitData.content,
          id: this.submitData.id || 0,
          isSolve: this.checked ? 1 : 0,
          processResult: this.submitData.processResult,
          resultDate: this.submitData.resultDate,
          userId: this.submitData.userId,
          userName: this.submitData.userName
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 是否涉军选择
    armyConfirm(value) {
      if (value) {
        this.selectMsg.armyStr = value.label
        this.submitData.army = value.value
      }
      this.armyShow = false
    },
    communityConfirm(value) {
      if (value) {
        this.selectMsg.communityStr = value.label
        this.submitData.community = value.value
      }
      this.communityShow = false
    },
    // 信访级别选择
    typeConfirm (value) {
      if (value) {
        this.selectMsg.typeStr = value.label
        this.submitData.type = value.value
      }
      this.typeShow = false
    },
    // 肇事类型选择
    inspectionTypeFinish(value) {
      if (value) {
        this.selectMsg.inspectionTypeStr = value.selectedOptions[0].label
        this.submitData.inspectionType = value.value
      }
      this.inspectionTypeShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.causeDate = formatterDate(value)
        this.submitData.causeDate = formatterDate(value)
      }
      this.causeDateShow = false
    },
    resultDateConfirm(value) {
      if (value) {
        this.selectMsg.resultDate = formatterDate(value)
        this.submitData.resultDate = formatterDate(value)
      }
      this.resultDateShow = false
    },
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    this.submitData.userId = this.$route.query.userId || ''
    this.submitData.userName = this.$route.query.userName || ''
    this.submitData.causeDate = formatterDate(new Date())
    this.submitData.resultDate = formatterDate(new Date())
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.init()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style  scoped>
.left >>> .van-field__control {
  text-align: left !important;
}
.left >>> .van-icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
<style lang="scss" scoped>
::v-deep .van-collapse-item__content {
  padding: 0;
}
</style>
